import React, { ChangeEvent } from 'react';
import './checkbox.css'
import validate from '../../helpers/validation'
import LoadingWheel from '../loadingwheel';
import BoardImage from 'react-img-mapper';
import pinImage from '../../assets/pin.png';
import autoLimitImage from '../../assets/images/autosetLimit-ai.png';
import SelectComponent from '../../components/select';
import constants from '../../config/constants';
import _ from 'lodash';
import authStore from '../../store/authStore';
import Permissions from '../../config/permssions'
import Utility from '../../helpers/Utility';

const {
  view_only_partial_asset_sensorbox_details
} = Permissions


const boardVersions = new Map([
  [3, "Rev3"],
  [6, "Rev6"],
  [7, "Rev7"]
])

export interface checkboxProps {
  data: Array<object>
  onChange: any
  name: string
  value: any
  sensorTemplates: any
  isDisabled: boolean
  sensor_name: string
  pinConfig: any
  boardVersion?: any
  isLegacy?: boolean
  errors?: any
  sensorBox?: any
  updatePinType: (sensorType: any, sensorTypeItem: any, type: string) => void
  updatePinRepresentation: (sensorType: any, sensorTypeItem: any, representation: string) => void
  selectAll: (event: ChangeEvent<HTMLInputElement>) => void;
  deselectAll: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeTemplate: any
  clickPinNumberOnBoardImage: any
  onDataChange: (minVal: any, maxVal: any, sensor_type: any, ml_accepted: boolean) => void;
}

export interface checkboxState {
  data: any[];
  isChecked: boolean,
  checkList: Array<object>,
  selectedTemplate: any
  selectedBoard: any,
  acceptAllSwitch: boolean,
  toggleStates: any,
  resetToggleState: any,
  sensorTypeItem: Array<object>,
  newModalMaxValue: { [key: number]: string };
  isEditedStates: any


}

class Checkbox extends React.Component<checkboxProps, checkboxState> {

  constructor(props: checkboxProps) {
    super(props);
    this.state = {
      data: [],
      isChecked: false,
      checkList: [],
      selectedBoard: { _id: '', name: 'Select' },
      selectedTemplate: { _id: '', name: 'Select' },
      acceptAllSwitch: false,
      toggleStates: this.props.data.reduce((acc: any, _, index) => {
        acc[index] = false;
        return acc;
      }, {}),
      resetToggleState: {},
      sensorTypeItem: [...props.data],
      newModalMaxValue: {},
      isEditedStates: this.props.data.reduce((acc: any, _, index) => {
        acc[index] = false;
        return acc;
      }, {}),

    }
  }

  toggleSwitch = () => {
    this.setState((prevState) => {
      const newState = !prevState.acceptAllSwitch; // Flip the main toggle state

      // Update all secondary toggles
      const updatedToggleStates = this.props.data.reduce((acc: any, _, index) => {
        acc[index] = newState;
        return acc;
      }, {});

      return {
        acceptAllSwitch: newState,
        toggleStates: updatedToggleStates,
        resetToggleState: this.props.data.reduce((acc: any, _, index) => {
          acc[index] = newState;
          return acc;
        }, {}),
      };
    });

    let sensors = this.state.sensorTypeItem
    sensors.forEach((newSensorConfig: any, index) => {
      let currentSensorConfig: any = this.props.data[index]
      let estMin = currentSensorConfig?.auto_limit?.latest_estimation?.min
      let estMax = currentSensorConfig?.auto_limit?.latest_estimation?.max
      let newMin = newSensorConfig?.auto_limit?.latest_estimation?.min || 0
      let newMax = newSensorConfig?.auto_limit?.latest_estimation?.max || 0

      if (newSensorConfig?.auto_limit?.latest_estimation) {
        newSensorConfig.auto_limit.latest_estimation.min = estMin
        newSensorConfig.auto_limit.latest_estimation.max = estMax
      }

      if (!this.state.acceptAllSwitch) {
        this.state.isEditedStates[index] = true
      } else {
        if (Number(newMin) !== Number(estMin) || Number(newMax) !== Number(estMax)) {
          this.state.isEditedStates[index] = true
        } else if (Number(newMin) === Number(estMin) && Number(newMax) === Number(estMax)) {
          this.state.isEditedStates[index] = false
        }
      }
    });
    this.setState({ sensorTypeItem: sensors });
  };

  toggleSwitchMLAccept = (index: any) => {
    let estMin = this.props.data[index]?.auto_limit?.latest_estimation?.min || 0
    let estMax = this.props.data[index]?.auto_limit?.latest_estimation?.max || 0
    let newMin = this.state.sensorTypeItem[index]?.auto_limit?.latest_estimation?.min || 0
    let newMax = this.state.sensorTypeItem[index]?.auto_limit?.latest_estimation?.max || 0

    let sensors = this.state.sensorTypeItem
    sensors[index].auto_limit.latest_estimation.min = estMin
    sensors[index].auto_limit.latest_estimation.max = estMax
    if (!this.state.toggleStates[index]) {
      this.state.isEditedStates[index] = true
    } else {
      if (Number(newMin) !== Number(estMin) || Number(newMax) !== Number(estMax)) {
        this.state.isEditedStates[index] = true
      } else if (Number(newMin) === Number(estMin) && Number(newMax) === Number(estMax)) {
        this.state.isEditedStates[index] = false
      }
    }

    this.setState({ sensorTypeItem: sensors });
    this.setState((prevState) => {
      const updatedToggleStates = {
        ...prevState.toggleStates,
        [index]: !prevState.toggleStates[index], // Toggle selected switch
      };

      return {
        toggleStates: updatedToggleStates,
        resetToggleState: {
          ...prevState.resetToggleState,
          [index]: !prevState.resetToggleState[index],
        },
      };
    }, () => {
      // Use the updated state correctly inside the callback
      const allOn = Object.values(this.state.toggleStates).every((state) => state === true);
      if (allOn !== this.state.acceptAllSwitch) {
        this.setState({ acceptAllSwitch: allOn });
      }
    });
  };

  async saveLimitChanges() {
    this.state.sensorTypeItem.forEach((newSensorConfig: any, index) => {
      let currentSensorConfig: any = this.props.data[index]
      let ml_accepted = false
      let manual_set = false
      if (newSensorConfig.auto_limit?.latest_estimation) {
        let newMin = newSensorConfig.auto_limit?.latest_estimation?.min || 0
        let newMax = newSensorConfig.auto_limit?.latest_estimation?.max || 0
        let estMin = currentSensorConfig.auto_limit?.latest_estimation?.min || 0
        let estMax = currentSensorConfig.auto_limit?.latest_estimation?.max || 0
        let prevMin = currentSensorConfig.min
        let prevMax = currentSensorConfig.max

        if (this.state.toggleStates[index] === true) {
          ml_accepted = true;
          this.props.onDataChange(estMin.toString(), estMax.toString(), newSensorConfig, ml_accepted)
        } else {
          // ML Estimation not acceptes
          if (newMin === estMin && newMax === estMax) {
            // No Manual update too; SKIP
          } else if (newMin !== prevMin || newMax !== prevMax) {
            manual_set = true;
            this.props.onDataChange(newMin.toString(), newMax.toString(), newSensorConfig, ml_accepted)
          }
        }
      }
    });
    this.setState({});
    Utility.closeModal('mlRecommendedList')
  }

  validRecommendNumber(e: any, index: any, item: any) {
    const value = e.target.value
    if (validate.isValidValue(value)) {
      item[e.target.name] = value;

      let currentSensorConfig: any = this.props.data[index]
      let newSensorConfig: any = this.state.sensorTypeItem[index]
      let newMin = e.target.name === "min" ? value : newSensorConfig.auto_limit?.latest_estimation?.min || 0
      let newMax = e.target.name === "max" ? value : newSensorConfig.auto_limit?.latest_estimation?.max || 0
      let estMin = currentSensorConfig.auto_limit?.latest_estimation?.min || 0
      let estMax = currentSensorConfig.auto_limit?.latest_estimation?.max || 0
      
      if (Number(newMin) !== Number(estMin) || Number(newMax) !== Number(estMax)) {
        this.state.isEditedStates[index] = true
      } else if (Number(newMin) === Number(estMin) && Number(newMax) === Number(estMax)) {
        this.state.isEditedStates[index] = false
      }

      this.setState({});
    }
  }

  closeForm = () => {
    Utility.closeModal('mlRecommendedList')
  }

  onModalBoxShow = () => {
    Utility.showModal('mlRecommendedList');

  }

  isChecked(sensor_type: any) {
    if (this.props.value && this.props.sensor_name) {
      if (sensor_type?.sensor_name_abbr === 'th_sensor1_db' && this.props.boardVersion >= 6) {
        return (this.props.value.findIndex((item: any) => item.sensor_name === sensor_type.sensor_name_abbr) >= 0) ||
          (this.props.value.findIndex((item: any) => item.sensor_name === 'th_sensor1_rh') >= 0);
      }
      if (sensor_type?.sensor_name_abbr === 'th_sensor2_db' && this.props.boardVersion >= 6) {
        return (this.props.value.findIndex((item: any) => item.sensor_name === sensor_type.sensor_name_abbr) >= 0) ||
          (this.props.value.findIndex((item: any) => item.sensor_name === 'th_sensor2_rh') >= 0);
      }

      return (this.props.value.findIndex((item: any) => item.sensor_name === sensor_type.sensor_name_abbr) >= 0);

    } else {
      return false
    }
  }

  isThPinCombinationChecked(sensor_type: any) {
    if (this.props.value && this.props.sensor_name) {
      return (this.props.value.findIndex((item: any) => item.sensor_name === sensor_type.sensor_name_abbr) >= 0);

    } else {
      return false
    }
  }

  isThPin1CombinationDisabled() {

    let sensorType = this.props.data.find((data: any) => (data.sensor_name_abbr === "th_sensor1_db"))
    return this.isChecked(sensorType)
  }

  isThPin2CombinationDisabled() {

    let sensorType = this.props.data.find((data: any) => (data.sensor_name_abbr === "th_sensor2_db"))
    return this.isChecked(sensorType)
  }

  getTipPosition = (area: any) => {
    return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
  }

  onClickedSensor = (sensor: any) => {
    const elementOfTheSensor = document.getElementById(`${sensor.name}`)
    if (elementOfTheSensor !== null) {
      elementOfTheSensor.scrollIntoView({
        behavior: "smooth"
      });
      if (!(this.props.isDisabled)) {
        let elementOfSensorCheckbox = document.getElementsByName(`${sensor.name}`)
        if (elementOfSensorCheckbox !== null) {
          elementOfSensorCheckbox.forEach((item: any) => {
            let pin = this.props.data.find((data: any) => (data.sensor_name_abbr === sensor.name))
            const groundedCondtion = (this.props?.sensorBox?.hasOwnProperty('_id') && item?.name === 'gpio_pin6' && this.props?.sensorBox?.version <= 3) ? false : item?.name === 'gpio_pin6'
            if (!groundedCondtion) {
              this.setState({ selectedTemplate: { _id: '', name: 'Select' }, selectedBoard: { _id: '', name: 'Select' } })
              this.props.clickPinNumberOnBoardImage(!(item.checked), pin);
            }
          })
        }
      }
    }
  }

  isGPIOChecked(sensor_type: any) {

    if (this.props.value && this.props.value.length) {
      const spi1_index: number = this.props.value.findIndex((sensor: any) => sensor.sensor_name === "spi_pin1")
      const spi2_index: number = this.props.value.findIndex((sensor: any) => sensor.sensor_name === "spi_pin2")
      let isGPIOPinChecked: boolean = this.isChecked(sensor_type) || false
      if (spi1_index > -1 && spi2_index > -1) {
        return this.props.value[spi1_index].sub_type === 'suction_temp' && this.props.value[spi2_index].sub_type === 'liquid_temp' && isGPIOPinChecked
      }
    }
  }

  validNumber(e: any, item: any) {
    if (validate.isValidValue(e.target.value)) {
      this.props.onChange(e, item)
    }
  }

  isCheckedCombination(item: any) {

    if (item?.sensor_name_abbr === 'th_sensor1_db') {
      return !(this.props.value.findIndex((item: any) => item.sensor_name === 'th_sensor1_db') >= 0)
    }

    if (item?.sensor_name_abbr === 'th_sensor2_db') {
      return !(this.props.value.findIndex((item: any) => item.sensor_name === 'th_sensor2_db') >= 0)
    }

    return !this.isChecked(item)

  }

  onMarkingPinsOnBoard(sensors: any) {

    let pins = [
      { name: "i2c_pin4", title: "1", shape: "circle", coords: [255, 51, 10], preFillColor: "white", center: [255, 51], href: "#/" },
      { name: "i2c_pin5", title: "2", shape: "circle", coords: [225, 51, 10], preFillColor: "white", center: [225, 51], href: "#/" },
      { name: "i2c_pin7", title: "3", shape: "circle", coords: [196, 51, 10], preFillColor: "white", center: [196, 51], href: "#/" },
      { name: "i2c_pin8", title: "4", shape: "circle", coords: [166, 52, 10], preFillColor: "white", center: [166, 52], href: "#/" },
      { name: "th_sensor1_db", title: "5", shape: "circle", coords: [137, 52, 10], preFillColor: "white", center: [137, 52], href: "#/" },
      { name: "th_sensor1_rh", title: "5", shape: "circle", coords: [137, 52, 10], preFillColor: "white", center: [137, 52], href: "#/" },
      { name: "th_sensor2_db", title: "6", shape: "circle", coords: [55, 141, 10], preFillColor: "white", center: [55, 141], href: "#/" },
      { name: "th_sensor2_rh", title: "6", shape: "circle", coords: [55, 141, 10], preFillColor: "white", center: [55, 141], href: "#/" },
      { name: "i2c_pin1", title: "7", shape: "circle", coords: [56, 174, 10], preFillColor: "white", center: [56, 174], href: "#/" },
      { name: "i2c_pin2", title: "8", shape: "circle", coords: [56, 207, 10], preFillColor: "white", center: [56, 207], href: "#/" },
      { name: "i2c_pin3", title: "9", shape: "circle", coords: [56, 236, 10], preFillColor: "white", center: [56, 236], href: "#/" },
      { name: "i2c_pin6", title: "10", shape: "circle", coords: [57, 269, 10], preFillColor: "white", center: [57, 269], href: "#/" },
      { name: "spi_pin2", title: "11", shape: "circle", coords: [139, 332, 10], preFillColor: "white", center: [139, 332], href: "#/" },
      { name: "spi_pin1", title: "12", shape: "circle", coords: [173, 331, 10], preFillColor: "white", center: [173, 331], href: "#/" },
      { name: "switch_1", title: "13", shape: "circle", coords: [541, 259, 10], preFillColor: "white", center: [541, 259], href: "#/" },
      { name: "switch_2", title: "14", shape: "circle", coords: [541, 218, 10], preFillColor: "white", center: [541, 218], href: "#/" },
      { name: "gpio_pin1", title: "15", shape: "circle", coords: [540, 184, 10], preFillColor: "white", center: [540, 184], href: "#/" },
      { name: "gpio_pin2", title: "16", shape: "circle", coords: [538, 156, 10], preFillColor: "white", center: [538, 156], href: "#/" },
      { name: "gpio_pin3", title: "17", shape: "circle", coords: [538, 127, 10], preFillColor: "white", center: [538, 127], href: "#/" },
      { name: "gpio_pin4", title: "18", shape: "circle", coords: [538, 98, 10], preFillColor: "white", center: [538, 98], href: "#/" },
      { name: "gpio_pin5", title: "19", shape: "circle", coords: [572, 185, 10], preFillColor: "white", center: [572, 185], href: "#/" },
      { name: "gpio_pin6", title: "20", shape: "circle", coords: [570, 155, 10], preFillColor: "white", center: [570, 155], href: "#/" },
      { name: "gpio_pin7", title: "21", shape: "circle", coords: [568, 127, 10], preFillColor: "white", center: [568, 127], href: "#/" }
    ]

    sensors?.forEach((sensor: any) => {

      if (sensor.sensor_name === 'th_sensor1_db') {
        let thpin1Index = pins.findIndex((pin => pin?.name === 'th_sensor1_rh'));
        if (thpin1Index >= 0) {
          pins[thpin1Index].preFillColor = "grey"
        }
      }

      if (sensor.sensor_name === 'th_sensor2_db') {
        let thpin1Index = pins.findIndex((pin => pin?.name === 'th_sensor2_rh'));
        if (thpin1Index >= 0) {
          pins[thpin1Index].preFillColor = "grey"
        }
      }

      let pinIndex = pins.findIndex((pin => pin?.name === sensor?.sensor_name));
      if (pinIndex >= 0) {
        pins[pinIndex].preFillColor = "grey"
      }
    })

    return pins
  }

  getTitlePartOfPins(item: any, groundedCondtion: any) {

    const display_name = this.getDisplayName(item)

    const pin_number = this.getPinNumber(item);
    if (this.props.boardVersion >= 6) {

      if (item.is_configurable.includes(17) || item.is_configurable.includes(18)) {
        return null
      } else {
        return (
          <>
            <label id={item.sensor_name_abbr} className="container-checkbox font-w-600 fs-18">{this.props.boardVersion >= 6 ? (display_name ? display_name : (item?.is_configurable.includes(0) ? item?.name : `Not in use`)) : item?.name}
              <input type="checkbox"
                className="chk-box"
                disabled={this.props.isDisabled || groundedCondtion}
                name={item.sensor_name_abbr}
                checked={this.isChecked(item) || false}
                onChange={(e) => { this.onClickCheck(e, item) }}
              />
              <span className="checkmark mt-1">{pin_number}</span>
            </label>
          </>
        )
      }

    } else {
      return (
        <>
          <span className="list-item">
            <label id={item.sensor_name_abbr} className="fs-18 font-w-600 pt-2 pb-2 mb-0">
              <input type="checkbox"
                className="chk-box"
                disabled={this.props.isDisabled || groundedCondtion}
                name={item.sensor_name_abbr}
                checked={this.isChecked(item) || false}
                onChange={(e) => { this.onClickCheck(e, item) }}
              /> {item.name}
            </label>
          </span>
        </>
      )

    }
  }
  getTitlePartOfModalPins(item: any, groundedCondtion: any) {

    const display_name = this.getDisplayName(item)

    const pin_number = this.getPinNumber(item);
    if (this.props.boardVersion >= 6) {

      if (item.is_configurable.includes(17) || item.is_configurable.includes(18)) {
        return null
      } else {
        return (
          <>
            <label id={item.sensor_name_abbr} className="container-checkbox font-w-500 fs-18" style={{ paddingLeft: 0 }}><div style={{ fontSize: 14 }}>
              {this.props.boardVersion >= 6 ? (display_name ? display_name : (item?.is_configurable.includes(0) ? item?.name : `Not in use`)) : item?.name}</div>
              <input type="checkbox"
                className="chk-box"
                disabled={this.props.isDisabled || groundedCondtion}
                name={item.sensor_name_abbr}
                checked={this.isChecked(item) || false}
              // onChange={(e) => { this.onClickCheck(e, item) }}
              />
            </label>
          </>
        )
      }

    } else {
      return (
        <>
          <span className="list-item">
            <label id={item.sensor_name_abbr} className="fs-18 font-w-600 pt-2 pb-2 mb-0">
              <input type="checkbox"
                className="chk-box"
                disabled={this.props.isDisabled || groundedCondtion}
                name={item.sensor_name_abbr}
                checked={this.isChecked(item) || false}
              // onChange={(e) => { this.onClickCheck(e, item) }}
              /> {item.name}
            </label>
          </span>
        </>
      )

    }
  }

  getNameTypeSelect(item: any) {

    if (item?.is_configurable.includes(1) && Object.keys(this.props.pinConfig).length) {

      return (
        <>
          <div className="col-md-6 col-sm-6 col-12 mt-2">
            <label className="fs-12">Type</label>
            <select className="form-control select-drop text-capitalize py-0"
              disabled={this.props.isDisabled || !this.isChecked(item) || this.props.isLegacy}
              value={item?.pin_type}
              onChange={(e) => {
                this.setState({ selectedTemplate: { _id: '', name: 'Select' }, selectedBoard: { _id: '', name: 'Select' } })
                this.props.updatePinType(item, this.state.sensorTypeItem, e.target.value)
              }}
            >
              <option value="">Select</option>
              {Object.keys(this.props.pinConfig['1'])?.map((key: string) =>
                (<option key={key} value={key}>{key}</option>))
              }
            </select>
          </div>
        </>
      )
    }
    else if (!item?.is_configurable.includes(0) && !item?.is_configurable.includes(1)) {

      let optionArray: any = []
      item.is_configurable.forEach((type: number) => {
        this.props.pinConfig[type.toString()] && this.props.pinConfig[type.toString()].forEach((config: any) => { optionArray.push(config) })
      })

      optionArray.sort((a: any, b: any) => a.display_name.localeCompare(b.display_name))
      const thCombinationPinClassName = ['mt-2']

      if ((item?.sensor_name_abbr === "th_sensor1_db" || item?.sensor_name_abbr === "th_sensor2_db" || item?.sensor_name_abbr === "th_sensor1_rh" || item?.sensor_name_abbr === "th_sensor2_rh") && this.props.boardVersion >= 6) {
        thCombinationPinClassName.push('col-5')
      } else {
        thCombinationPinClassName.push('col-md-6 col-sm-6 col-12 mt-2')
      }

      return (
        <>
          <div className={thCombinationPinClassName.join(' ')}>
            <label className="fs-12">Type</label>
            <select className="form-control select-drop text-capitalize py-0"
              disabled={this.props.isDisabled || this.isCheckedCombination(item) || this.props.isLegacy}
              value={item?.representation}
              onChange={(e: any) => {
                this.setState({ selectedTemplate: { _id: '', name: 'Select' }, selectedBoard: { _id: '', name: 'Select' } })
                this.props.updatePinType(item, this.state.sensorTypeItem, JSON.parse(e.target.selectedOptions[0].getAttribute('data-tag')))
              }}
            >
              <option value="">Select</option>
              {optionArray.map((config: any) =>
              (<option
                key={config.representation} data-tag={JSON.stringify(config)} value={config.representation}>{config.display_name}</option>)
              )
              }
            </select>
          </div>
        </>
      )
    }
  }

  getNameTypeModalSelect(item: any) {

    if (item?.is_configurable.includes(1) && Object.keys(this.props.pinConfig).length) {

      return (
        <>
          <select className=" select-drop text-capitalize py-0 getNameTypeModalSelect"
            disabled={true}
            value={item?.pin_type}
            onChange={(e) => {

              this.setState({ selectedTemplate: { _id: '', name: 'Select' }, selectedBoard: { _id: '', name: 'Select' } })
              this.props.updatePinType(item, this.state.sensorTypeItem, e.target.value)
            }}
          >
            <option value="">Select</option>

            {Object.keys(this.props.pinConfig['1'])?.map((key: string) => {
              return (
                <option key={key} value={key}>
                  {key}
                </option>
              );
            })}

          </select>
        </>
      )
    }
    else if (!item?.is_configurable.includes(0) && !item?.is_configurable.includes(1)) {

      let optionArray: any = []
      item.is_configurable.forEach((type: number) => {
        this.props.pinConfig[type.toString()] && this.props.pinConfig[type.toString()].forEach((config: any) => { optionArray.push(config) })
      })

      optionArray.sort((a: any, b: any) => a.display_name.localeCompare(b.display_name))
      const thCombinationPinClassName = ['mt-2']

      if ((item?.sensor_name_abbr === "th_sensor1_db" || item?.sensor_name_abbr === "th_sensor2_db" || item?.sensor_name_abbr === "th_sensor1_rh" || item?.sensor_name_abbr === "th_sensor2_rh") && this.props.boardVersion >= 6) {
        thCombinationPinClassName.push('col-5')
      } else {
        thCombinationPinClassName.push('col-md-6 col-sm-6 col-12 mt-2')
      }
      return (
        <>
          {/* <div className={thCombinationPinClassName.join(' ')}> </div> */}
          <select className="getNameTypeModalSelect select-drop text-capitalize py-0"
            disabled={true}
            value={item?.representation}
            onChange={(e: any) => {
              this.setState({ selectedTemplate: { _id: '', name: 'Select' }, selectedBoard: { _id: '', name: 'Select' } })
              this.props.updatePinType(item, this.state.sensorTypeItem, JSON.parse(e.target.selectedOptions[0].getAttribute('data-tag')))
            }}
          >
            <option value="">Select</option>
            {optionArray.map((config: any) =>
            (<option
              key={config.representation} data-tag={JSON.stringify(config)} value={config.representation}>{config.display_name}</option>)
            )
            }
          </select>

        </>
      )
    }
  }

  handleTemplateSelection = (templateValue: any) => {
    this.setState({ selectedTemplate: templateValue })
    this.props.onChangeTemplate(templateValue)
  }

  handleBoardSelection = (boardValue: any) => {
    this.setState({ selectedBoard: boardValue, selectedTemplate: { _id: '', name: 'Select' } })
  }

  onClickCheck = (e: any, item: any) => {
    this.setState({ selectedTemplate: { _id: '', name: 'Select' }, selectedBoard: { _id: '', name: 'Select' } })
    this.props.onChange(e, item)
  }

  onSelectAll = (e: any) => {
    this.setState({ selectedTemplate: { _id: '', name: 'Select' }, selectedBoard: { _id: '', name: 'Select' } })
    this.props.selectAll(e)
  }

  onDeselectAll = (e: any) => {
    this.setState({ selectedTemplate: { _id: '', name: 'Select' }, selectedBoard: { _id: '', name: 'Select' } })
    this.props.deselectAll(e)
  }

  getPinNumber = (item: any) => {
    let pin_number = ''
    let sensorType = this.props.data?.find((val: any) => (val?.sensor_name_abbr === item?.sensor_name_abbr))?.pin_order
    let val = sensorType?.find((val: any) => (val.board_version === this.props.boardVersion))
    pin_number = val?.pin_number
    return pin_number
  }

  getDisplayName = (item: any) => {

    let display_name = '';

    if (item?.is_configurable.includes(1) && Object.keys(this.props.pinConfig).length) {

      let options = this.props.pinConfig['1'][item?.pin_type]
      let matchedPin = options?.find((val: any) => (val.representation === item?.representation))
      display_name = matchedPin?.display_name

    } else if (!item?.is_configurable.includes(0) && !item?.is_configurable.includes(1) && item?.sensor_name_abbr !== "th_sensor1_rh" && item?.sensor_name_abbr !== "th_sensor2_rh" && item?.sensor_name_abbr !== "th_sensor1_db" && item?.sensor_name_abbr !== "th_sensor2_db") {

      let optionArray: any = []
      item.is_configurable.forEach((type: number) => {
        this.props.pinConfig[type.toString()] && this.props.pinConfig[type.toString()].forEach((config: any) => { optionArray.push(config) })
      })
      let matchedPin = optionArray?.find((val: any) => (val.representation === item?.representation))
      display_name = matchedPin?.display_name

    }
    else if (item?.sensor_name_abbr === "th_sensor1_db" || item?.sensor_name_abbr === "th_sensor1_rh") {
      let optionArray: any = []
      let configurable = [3, 4, 9, 17, 19]
      configurable.forEach((type: number) => {
        this.props.pinConfig[type.toString()] && this.props.pinConfig[type.toString()].forEach((config: any) => { optionArray.push(config) })
      })

      let pins = this.props.value.filter((val: any) => (val.sensor_name === "th_sensor1_db" || val.sensor_name === "th_sensor1_rh"))

      let pinsName = pins?.map((template: any) => {
        return template.representation
      })

      let name = optionArray.filter((option: any) => (pinsName.includes(option.representation))).map((template: any) => {
        return template.display_name
      })

      display_name = name.join(', ')

    }
    else if (item?.sensor_name_abbr === "th_sensor2_db" || item?.sensor_name_abbr === "th_sensor2_rh") {
      let optionArray: any = []
      let configurable = [3, 4, 9, 18, 19]
      configurable.forEach((type: number) => {
        this.props.pinConfig[type.toString()] && this.props.pinConfig[type.toString()].forEach((config: any) => { optionArray.push(config) })
      })

      let pins = this.props.value.filter((val: any) => (val.sensor_name === "th_sensor2_db" || val.sensor_name === "th_sensor2_rh"))

      let pinsName = pins?.map((template: any) => {
        return template.representation
      })

      let name = optionArray.filter((option: any) => (pinsName.includes(option.representation))).map((template: any) => {
        return template.display_name
      })

      display_name = name.join(', ')

    }
    else if (item?.is_configurable.includes(0)) { // sc, sh, blower current etc ...
      let matchedPin = this.props.value.find((val: any) => (val.representation === item?.represent))
      if (matchedPin) {
        display_name = item?.name
      }

    }

    return display_name

  }

  // Update local state if props.users changes
  componentDidUpdate(prevProps: checkboxProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.setState({ sensorTypeItem: [...this.props.data] });
    }
  }

  componentDidMount() {

    const toggleStates: Record<number, boolean> = {}
    this.props.data.forEach((item, index) => {
      toggleStates[index] = false;
    });

    const editedStates: Record<number, boolean> = {}
    this.props.data.forEach((item, index) => {
      editedStates[index] = false;
    });
    this.setState({ toggleStates, isEditedStates: editedStates });
  }

  render() {

    // console.log("this.props.data => =>" , this.props.data)

    let devicesWidth = '20%'
    let lastUpdatedWidth = '17%'
    if (authStore?.currentUser?.active_role?.role_type !== 4) {
      devicesWidth = '17%'
      lastUpdatedWidth = '13%'
    }

    if (authStore?.currentUser?.active_role?.role_type === 1) {
      devicesWidth = '13%'
      lastUpdatedWidth = '10%'
    }


    const groundedCondtionForCheckBox = (this.props?.sensorBox?.hasOwnProperty('_id') && this.props?.sensorBox?.version <= 3) ? true : false
    const pins = this.onMarkingPinsOnBoard(this.props.sensorBox?.sensors)

    const pinSensors = {
      name: 'my-map',
      areas: pins,
    };

    const pinsExcludingCombinationPins = this.props.value?.filter((val: any) => (val.sensor_name !== 'th_sensor1_db' && val.sensor_name !== 'th_sensor2_db' && val.sensor_name !== 'th_sensor1_rh' && val.sensor_name !== 'th_sensor2_rh'))

    let thPin1AndReturnpins = ['th_sensor1_db', 'th_sensor1_rh']
    const thPin1AndReturnCombinationExists = this.props.value.some((value: any) => thPin1AndReturnpins.includes(value.sensor_name));

    let thPin2AndSupplypins = ['th_sensor2_db', 'th_sensor2_rh']
    const thPin2AndSupplyCombinationExists = this.props.value.some((value: any) => thPin2AndSupplypins.includes(value.sensor_name));

    const templateCheckingWithBoardVersion = this.props.sensorTemplates?.filter((template: any) => template.board_version.includes(this.props.boardVersion))


    const senorsClassName = ['pins-config-min-height']

    if (this.props.boardVersion >= 6) {
      senorsClassName.push('col-6 col-100')
    } else {
      senorsClassName.push('col-12')
    }

    const templateGrouped = _.groupBy(templateCheckingWithBoardVersion, 'board_category');

    const boardOptions = Object.keys(templateGrouped).map((template: any) => {
      const templateOption = {
        _id: template,
        name: template
      }
      return templateOption
    })

    const tempOptions = templateCheckingWithBoardVersion.filter((template: any) => template?.board_category === this.state.selectedBoard?._id).map((template: any) => {
      const templateOption = {
        _id: `${template.name}-${template.board_category}`,
        name: template.name,
        config: template.config
      }
      return templateOption
    })

    const selectAllCheckedCondition = (groundedCondtionForCheckBox ? (this.props.boardVersion >= 6 ? ((pinsExcludingCombinationPins.length === 21 && thPin1AndReturnCombinationExists && thPin2AndSupplyCombinationExists) ? true : this.props.value?.length === (this.props.data?.length - 1)) : this.props.value?.length === (this.props.data?.length - 1)) : (this.props.boardVersion >= 6 ? ((pinsExcludingCombinationPins.length === 20 && thPin1AndReturnCombinationExists && thPin2AndSupplyCombinationExists) ? true : this.props.value?.length === (this.props.data?.length - 2)) : this.props.value?.length === (this.props.data?.length - 2)))

    //sc sh conditions
    const presLiqExists = this.props.value.findIndex((item: any) => item.representation === 'pres_liq') >= 0
    const tempLiqExists = this.props.value.findIndex((item: any) => item.representation === 'temp_liq') >= 0

    const scCondition = ((this.props.sensorBox?.refrigerent !== undefined && this.props.sensorBox?.refrigerent !== null)
      && presLiqExists && tempLiqExists)

    const presSuctionExists = this.props.value.findIndex((item: any) => item.representation === 'pres_suc') >= 0
    const tempSuctionExists = this.props.value.findIndex((item: any) => item.representation === 'temp_suc') >= 0

    const shCondition = ((this.props.sensorBox?.refrigerent !== undefined && this.props.sensorBox?.refrigerent !== null)
      && presSuctionExists && tempSuctionExists)


    const scSelected = this.props.value.findIndex((item: any) => item.representation === 'sc') >= 0

    const shSelected = this.props.value.findIndex((item: any) => item.representation === 'sh') >= 0

    return (
      <div className="row" >
        {this.props.data.length > 0 ?
          <>
            {this.props.boardVersion >= 6 &&
              <div className='col-100 col-6 position-relative'>
                <div className='row'>
                  <div className='col-12 d-flex pr-0  mb-2 align-items-center'>
                    <label className="fs-18 pt-2 pb-2 mb-0 font-w-600">{`Board Version : ${boardVersions.get(this.props.boardVersion)}`}</label>
                  </div>

                  <div className='col-12 d-flex pr-0  mb-2 align-items-center z-index-board-lg'>

                    <div className='w-50 mr-3'>
                      <div className="w-100 z-index-board">
                        <label className="custom-label font-w-600">Board</label>
                        <SelectComponent
                          name="typeFilter"
                          value={this.state.selectedBoard}
                          onChange={(value: any) => this.handleBoardSelection(value)}
                          data={boardOptions}
                          isDisabled={this.props.isDisabled}
                        />
                      </div>
                    </div>
                    <div className='w-50 mr-3'>
                      <div className="w-100 z-index-board">
                        <label className="custom-label font-w-600">Template</label>
                        <SelectComponent
                          name="typeFilter"
                          value={this.state.selectedTemplate}
                          onChange={(value: any) => this.handleTemplateSelection(value)}
                          data={tempOptions}
                          isDisabled={this.props.isDisabled}
                        />
                      </div>
                    </div>
                  </div>

                </div>

                <div className='position-relative' style={{ width: 600, height: 419, margin: "auto" }}>
                  <BoardImage
                    src={pinImage}
                    map={pinSensors}
                    width={600}
                    height={419}
                    onClick={(sensor: any) => { this.onClickedSensor(sensor); }}
                  />
                  {
                    pinSensors?.areas?.map((sensor: any, index: any) => {
                      return (
                        <span className="tooltip-style cursor-pointer font-w-600"
                          style={{ ...this.getTipPosition(sensor) }}
                        >
                          {sensor?.title}
                        </span>

                      )
                    })
                  }
                </div>
              </div>
            }
            <div className={senorsClassName.join(' ')}>
              <div className='row' style={{ marginBottom: 10 }}>
                {
                  !this.props.isLegacy && !authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) &&
                  <div className='col-6'>
                    <div className="mt-3 pl-3">


                    </div>
                  </div>
                }
                {
                  !this.props.isLegacy && !authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) &&
                  <div className='col-6 mb-2 '>

                    <button type="button"
                      className="btn btn-edit"
                      disabled={!(this.props.value?.length > 0) || this.props.isDisabled}
                      onClick={this.onDeselectAll}
                    >
                      Clear All
                    </button>

                    <button type="button"
                      className="btn btn-edit"
                      style={{ marginRight: 10 }}
                      disabled={!(this.props.value?.length > 0) || this.props.isDisabled}
                      onClick={this.onModalBoxShow}
                    >
                      Quick Limit Setup
                    </button>
                  </div>
                }
              </div>
              <main className="border">
                <div className="mb-4">
                  {this.props.data && this.props.data.map((item: any, index: any) => {
                    // console.log("====this.props====", this.props)
                    // console.log("====this.item====", item)

                    const groundedCondtion = (this.props?.sensorBox?.hasOwnProperty('_id') && item?.sensor_name_abbr === 'gpio_pin6' && this.props?.sensorBox?.version <= 3) ? false : item?.sensor_name_abbr === 'gpio_pin6'
                    if (item?.sensor_name_abbr === 'blower_current' && !this.props.isLegacy) {
                      return null;
                    }
                    if (item?.board_version.includes(this.props.boardVersion)) {
                      return (
                        <>
                          <div className="col-12 ins_tab_head_bg">
                            {this.getTitlePartOfPins(item, groundedCondtion)}
                          </div>

                          <div className="pl-4 pr-4">
                            <div className="row mb-4" style={{ alignItems: 'center' }}>

                              {(item?.sensor_name_abbr === "th_sensor1_db" || item?.sensor_name_abbr === "th_sensor2_db" || item?.sensor_name_abbr === "th_sensor1_rh" || item?.sensor_name_abbr === "th_sensor2_rh") && this.props.boardVersion >= 6 &&
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="checkbox-energized d-flex align-items-center mt-2">
                                    <input type="checkbox"
                                      className="chk-box"
                                      name="thpincheckbox"
                                      disabled={(item?.sensor_name_abbr === "th_sensor1_db" || item?.sensor_name_abbr === "th_sensor1_rh") ? !this.isThPin1CombinationDisabled() || this.props.isDisabled || this.props.isLegacy : !this.isThPin2CombinationDisabled() || this.props.isDisabled || this.props.isLegacy}
                                      checked={this.isThPinCombinationChecked(item) || false}
                                      onChange={(e) => { this.props.onChange(e, item); }} />
                                  </div>
                                </div>
                              }
                              {groundedCondtion ?
                                <div className="mt-2">
                                  <p>This pin is not selectable</p>
                                </div>
                                :
                                this.getNameTypeSelect(item)
                              }

                              {item?.is_configurable.includes(9) &&
                                <div className="col-md-12 col-sm-6 col-12 mt-2">
                                  <div className="checkbox-energized d-flex align-items-center mt-2">
                                    <input type="checkbox"
                                      className="chk-box"
                                      disabled={this.props.isDisabled || !this.isChecked({ sensor_name_abbr: 'gpio_pin4' }) || this.props.isLegacy}
                                      name={`${item.sensor_name_abbr}_energized`}
                                      checked={this.isGPIOChecked(item) || false}
                                      onChange={(e) => { this.props.onChange(e, item); }} />
                                    <label className="chk-bx-label m-0">GPIO Energize</label>
                                  </div>
                                </div>
                              }
                              {item?.is_configurable.includes(1) && Object.keys(this.props.pinConfig).length &&
                                <div className="col-md-6 col-sm-6 col-12 mt-2">
                                  <label className="fs-12">Sub Type</label>
                                  <select className="form-control select-drop text-capitalize py-0"
                                    disabled={this.props.isDisabled || !this.isChecked(item) || this.props.isLegacy}
                                    value={item?.representation}
                                    onChange={(e: any) => {
                                      this.setState({ selectedTemplate: { _id: '', name: 'Select' }, selectedBoard: { _id: '', name: 'Select' } })
                                      this.props.updatePinRepresentation(item, this.state.sensorTypeItem, JSON.parse(e.target.selectedOptions[0].getAttribute('data-tag')))
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {
                                      this.props.pinConfig['1'][item?.pin_type]?.map((d: any) =>
                                        (<option key={d?._id} data-tag={JSON.stringify(d)} value={d?.representation}>{d?.display_name}</option>))
                                    }
                                  </select>
                                </div>
                              }

                              {item.min !== undefined && item.max !== undefined ?
                                <div className="col-md-6 col-sm-6 col-12 mt-2">
                                  <label className="fs-12">Current Limits</label>
                                  <div className='row pr-3'>
                                    <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                                      <input type="text" className="form-control min-max" aria-describedby="emailHelp"
                                        name="min"
                                        value={item.min ? item.min : ''}
                                        onChange={(e) => { this.validNumber(e, item) }}
                                        disabled={this.props.isDisabled || this.isCheckedCombination(item)}
                                        maxLength={8}
                                        placeholder="Enter min value" />
                                    </div>
                                    <div className='col-6 d-flex pr-0 mb-2 align-items-center'>
                                      <input type="text" className="form-control min-max" aria-describedby="emailHelp"
                                        name="max"
                                        // value={this.state.resetToggleState[index] ? item?.auto_limit?.latest_estimation?.max :item.max ? item.max : ''}
                                        value={item.max ? item.max : ''}

                                        onChange={(e) => { this.validNumber(e, item) }}
                                        disabled={this.props.isDisabled || this.isCheckedCombination(item)}
                                        maxLength={8}
                                        placeholder="Enter max value" />

                                    </div>
                                  </div>
                                </div>
                                : null
                              }




                              {item?.is_configurable.includes(1) &&
                                <div className="col-md-6 col-sm-6 col-12 mt-2">
                                  <label className="fs-12">Calibration</label>
                                  <div className='row m-0'>
                                    <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                                      <label className="fs-12 pr-3 mb-0">a</label>
                                      <input type="text" className="form-control calibration" aria-describedby="emailHelp"
                                        name="calibration_a"
                                        value={item?.calibration_a}
                                        onChange={(e) => { this.validNumber(e, item) }}
                                        disabled={this.props.isDisabled || !this.isChecked(item)}
                                        placeholder="Calibration factor" />


                                    </div>
                                    <div className='col-6 d-flex pr-0 mb-2 align-items-center'>
                                      <label className="fs-12 pr-3 mb-0">b</label>
                                      <input type="text" className="form-control calibration" aria-describedby="emailHelp"
                                        name="calibration_b"
                                        value={item?.calibration_b}
                                        onChange={(e) => { this.validNumber(e, item) }}
                                        disabled={this.props.isDisabled || !this.isChecked(item)}
                                        placeholder="Calibration factor" />

                                    </div>

                                    <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                                      <label className="fs-12 pr-3 mb-0">c</label>
                                      <input type="text" className="form-control calibration" aria-describedby="emailHelp"
                                        name="calibration_c"
                                        value={item?.calibration_c}
                                        onChange={(e) => { this.validNumber(e, item) }}
                                        disabled={this.props.isDisabled || !this.isChecked(item)}
                                        placeholder="Calibration factor" />

                                    </div>


                                    <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                                      <label className="fs-12 pr-3 mb-0">d</label>
                                      <input type="text" className="form-control calibration" aria-describedby="emailHelp"
                                        name="calibration_d"
                                        value={item?.calibration_d}
                                        onChange={(e) => { this.validNumber(e, item) }}
                                        disabled={this.props.isDisabled || !this.isChecked(item)}
                                        placeholder="Calibration factor" />

                                    </div>

                                  </div>
                                </div>
                              }

                              {/* {!item?.is_configurable.some((num:any) => [1, 2, 3, 4, 7, 8, 9, 10].includes(num)) && (
                                <div className="col-md-6 col-sm-6 col-12 mt-2"></div>
                              )} */}




                            </div>
                          </div>
                          {
                            this.props.errors && this.props.errors[item?.sensor_name_abbr] &&
                            <div className="error-msg border-0 py-0 pb-1 ml-4 mb-4">
                              {this.props.errors[item?.sensor_name_abbr]}
                            </div>
                          }
                          {item?.sensor_name_abbr === 'sc' && !scCondition && scSelected &&
                            <div className="warning-msg border-0 py-0 pb-1 ml-4 mb-4">{constants.message.scWarningMessage}</div>
                          }
                          {item?.sensor_name_abbr === 'sh' && !shCondition && shSelected &&
                            <div className="warning-msg border-0 py-0 pb-1 ml-4 mb-4">{constants.message.shWarningMessage}</div>
                          }
                        </>
                      )
                    }
                    return null
                  }
                  )}
                </div>

              </main>
            </div>
          </>
          :
          <LoadingWheel />

        }
        {/* Modal */}
        <div className="modal fade bd-example-modal-lg" id="mlRecommendedList" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div className="modal-dialog sensor-modal-lg xxx table-style" >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Edit ML Recommended Sensor Limits ({this.props.sensor_name} - {this.props.sensorBox?.alias || ''})</h5>
                <span aria-hidden="true" className="close close-modal" aria-label="Close" onClick={this.closeForm}>&times;</span>

              </div>
              <div className="modal-body modal-text">
                {/* Modal Data here */}
                <div className='switch-head' style={{ marginBottom: 20 }}>
                  <div className={`toggle-switch ${this.state.acceptAllSwitch ? "on" : "off"}`}
                    onClick={this.toggleSwitch}>
                    <div className="toggle-handle"></div>
                  </div>
                  <span className='font-w-600'>Accept All ML/AI Recommended Limits</span>
                </div>
                <table className="table table-custom custom-table  table-hover" style={{ tableLayout: 'fixed' }}>
                  <thead className="table-header-bg">
                    <tr>
                      <th style={{ width: '1%' }} className="" scope="col"></th>
                      <th style={{ width: '10%' }} className="" scope="col">Name</th>

                      <th style={{ width: '11%' }} className="" scope="col" >
                        Type
                      </th>
                      <th style={{ width: '11%' }} className="" scope="col">Sub Type</th>
                      <th style={{ width: '4%' }} className="" scope="col">Current Min</th>
                      <th style={{ width: '4%' }} className="" scope="col">Current Max</th>
                      <th style={{ width: '6%' }} className="" scope="col">Editable/ML Min</th>
                      <th style={{ width: '6%' }} className="" scope="col">Editable/ML Max</th>
                      <th style={{ width: '5%' }} className="" scope="col">Accept New limit</th>
                    </tr>
                  </thead>
                  <tbody>

                    {this.state.sensorTypeItem && this.state.sensorTypeItem.map((item: any, index: any) => {
                      const groundedCondtion = (this.props?.sensorBox?.hasOwnProperty('_id') && item?.sensor_name_abbr === 'gpio_pin6' && this.props?.sensorBox?.version <= 3) ? false : item?.sensor_name_abbr === 'gpio_pin6'
                      if (item?.sensor_name_abbr === 'blower_current' && !this.props.isLegacy) {
                        return null;
                      }
                      if (item?.pin_type === 'humidity') {
                        return null;
                      }
                      if (item?.board_version.includes(this.props.boardVersion)) {


                        return (
                          <>
                            {this.isCheckedCombination(item) ? null :

                              <>
                                {item?.min === undefined && item.max === undefined ? null : <tr>
                                  <td>
                                  {this.state.isEditedStates[index] && (
                                      this.state.acceptAllSwitch || this.state.toggleStates[index] ? (
                                          <img src={autoLimitImage} alt="Edit" style={{width:25, height:25,marginLeft:-7}}/>
                                      ) : (
                                          <i className="fa fa-edit" aria-hidden="true"></i>
                                      )
                                  )}

                                  </td>
                                  <td>
                                    <div className="col-12 " style={{ display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
                                     
                                      {this.getTitlePartOfModalPins(item, groundedCondtion)}
                                    </div>
                                  </td>

                                  <td>
                                    {
                                      this.getNameTypeModalSelect(item)
                                    }

                                  </td>

                                  <td>
                                    {item?.is_configurable.includes(1) && Object.keys(this.props.pinConfig).length &&
                                      <select className="getNameTypeModalSelect select-drop text-capitalize py-0"
                                        disabled={true}
                                        value={item?.representation}
                                        onChange={(e: any) => {
                                          this.setState({ selectedTemplate: { _id: '', name: 'Select' }, selectedBoard: { _id: '', name: 'Select' } })
                                          this.props.updatePinRepresentation(item, JSON.parse(e.target.selectedOptions[0].getAttribute('data-tag')))
                                        }}
                                      >
                                        <option value="">Select</option>
                                        {
                                          this.props.pinConfig['1'][item?.pin_type]?.map((d: any) =>
                                            (<option key={d?._id} data-tag={JSON.stringify(d)} value={d?.representation}>{d?.display_name}</option>))
                                        }
                                      </select>
                                    }
                                  </td>

                                  <td>
                                    {item.min !== undefined ?

                                      <div>{item.min ? item.min : ''}</div>
                                      : null
                                    }
                                  </td>

                                  <td>
                                    {item.max !== undefined ?
                                      <div>{item.max ? item.max : ''}</div> : null
                                    }
                                  </td>
                                  <td>
                                    {item?.auto_limit !== undefined ?
                                      <input type="text" className="form-control min-max" aria-describedby="emailHelp"
                                        name="min"
                                        value={
                                          this.state.resetToggleState[index]
                                            ? item?.auto_limit?.latest_estimation?.min
                                              ? item?.auto_limit?.latest_estimation?.min
                                              : '0'
                                            : item?.auto_limit?.latest_estimation
                                              ? item?.auto_limit?.latest_estimation?.min
                                              : '0'
                                        }
                                        onChange={(e) => { this.validRecommendNumber(e, index, item?.auto_limit?.latest_estimation) }}
                                        disabled={this.props.isDisabled || this.isCheckedCombination(item) || this.state.resetToggleState[index]}
                                        maxLength={8}
                                        placeholder="Enter min value" /> : null}

                                  </td>

                                  <td>
                                    {item?.auto_limit !== undefined ? <input type="text" className="form-control min-max" aria-describedby="emailHelp"
                                      name="max"
                                      value={
                                        this.state.resetToggleState[index]
                                          ? item?.auto_limit?.latest_estimation?.max
                                            ? item?.auto_limit?.latest_estimation?.max
                                            : '0'
                                          : item?.auto_limit?.latest_estimation
                                            ? item?.auto_limit?.latest_estimation?.max
                                            : '0'
                                      }
                                      onChange={(e) => {
                                        this.validRecommendNumber(e, index, item?.auto_limit?.latest_estimation);
                                      }}
                                      disabled={this.props.isDisabled || this.isCheckedCombination(item) || this.state.resetToggleState[index]}
                                      maxLength={8}
                                      placeholder="Enter max value" /> : null}

                                  </td>

                                  <td>
                                    <div className={`switch-head ${this.props.isDisabled ? "disabled" : ""}`} style={{ marginLeft: 10 }}

                                    >
                                      <div className={`toggle-switch ${this.state.toggleStates[index] ? "on" : "off"} ${this.props.isDisabled ? "disabled" : ""}`}
                                        onClick={(e) => {
                                          if (this.props.isDisabled) {
                                            e.stopPropagation();
                                            return;
                                          }
                                          this.toggleSwitchMLAccept(index);
                                        }}>
                                        <div className="toggle-handle"></div>
                                      </div>
                                    </div>

                                  </td>

                                </tr>}
                              </>
                            }
                          </>
                        )
                      }
                      return null
                    }
                    )}
                  </tbody>
                </table>
                <button type="button" style={{ float: 'right', margin: "20px 0" }} className="btn btn-dark-blue" onClick={() => this.saveLimitChanges()}>Confirm Limits</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default Checkbox;
